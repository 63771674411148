import { createAction, props } from '@ngrx/store';
import {
	LoginCredentialsDTO,
	RegisterUserDTO,
	ResendOTPDTO,
	PasswordForgottenDTO,
	ResetPasswordDTO,
	VerifyOTPDTO,
	ResendActivateDTO,
} from '../../application/dtos/authentication.dto';
import { UserContract } from '../../domain/entities/user-contract.entity';
import { LegalEntity } from '../../domain/entities/legal-entity.entity';

export const login = createAction(
	'[Auth] Login',
	props<{ credentials: LoginCredentialsDTO }>()
);
export const loginSuccess = createAction(
	'[Auth] Login Success',
	props<{ token: string; refreshToken: string; user: any }>()
);
export const loginFailure = createAction(
	'[Auth] Login Failure',
	props<{ error: any }>()
);

export const register = createAction(
	'[Auth] Register',
	props<{ user: RegisterUserDTO }>()
);
export const registerSuccess = createAction(
	'[Auth] Register Success',
	props<{ registerData: any }>()
);
export const registerFailure = createAction(
	'[Auth] Register Failure',
	props<{ error: any }>()
);

export const logout = createAction('[Auth] Logout');
export const logoutSuccess = createAction('[Auth] Logout Success');
export const logoutFailure = createAction(
	'[Auth] Logout Failure',
	props<{ error: any }>()
);

export const activateAccount = createAction(
	'[Auth] Activate Account',
	props<{ userName: string; activationCode: string }>()
);
export const activateAccountSuccess = createAction(
	'[Auth] Activate Account Success',
	props<{ serviceKey: string }>()
);
export const activateAccountFailure = createAction(
	'[Auth] Activate Account Failure',
	props<{ error: any }>()
);

export const createPassword = createAction(
	'[Auth] Create Password',
	props<{ serviceKey: string; password: string }>()
);
export const createPasswordSuccess = createAction(
	'[Auth] Create Password Success'
);
export const createPasswordFailure = createAction(
	'[Auth] Create Password Failure',
	props<{ error: any }>()
);

export const clearAuthError = createAction('[Auth] Clear Error');

export const getUserContracts = createAction('[Auth] Get User Contracts');
export const getUserContractsSuccess = createAction(
	'[Auth] Get User Contracts Success',
	props<{ contracts: UserContract[] }>()
);
export const getUserContractsFailure = createAction(
	'[Auth] Get User Contracts Failure',
	props<{ error: any }>()
);

export const getLegalEntity = createAction('[Auth] Get Legal Entity');
export const getLegalEntitySuccess = createAction(
	'[Auth] Get Legal Entity Success',
	props<{ legalEntity: LegalEntity[] }>()
);
export const getLegalEntityFailure = createAction(
	'[Auth] Get Legal Entity Failure',
	props<{ error: any }>()
);

export const passwordForgotten = createAction(
	'[Auth] Password forgotten',
	props<{ credentials: PasswordForgottenDTO }>()
);
export const passwordForgottenSuccess = createAction(
	'[Auth] Password forgotten Success',
	props<{
		user_attributes: UserAttributes[];
		isUserExists: string;
		serviceKey: string;
		securityKey: string;
		email: string;
		phone: string;
		userName: string;
		sacCodeLength: string;
	}>()
);
export const passwordForgottenFailure = createAction(
	'[Auth] Password forgotten Failure',
	props<{ error: any }>()
);

export const resendOTP = createAction(
	'[Auth] resend OTP',
	props<{ credentials: ResendOTPDTO }>()
);
export const resendOTPSuccess = createAction(
	'[Auth] resend OTP Success',
	props<{ resendOTP: any }>()
);
export const resendOTPFailure = createAction(
	'[Auth] resend OTP Failure',
	props<{ error: any }>()
);

export const resetPassword = createAction(
	'[Auth] Reset Password',
	props<{ credentials: ResetPasswordDTO }>()
);
export const resetPasswordSuccess = createAction(
	'[Auth] Reset Password Success',
	props<{ resetPassword: any }>()
);
export const resetPasswordFailure = createAction(
	'[Auth] Reset Password Failure',
	props<{ error: any }>()
);

export const verifyOTP = createAction(
	'[Auth] Verify OTP',
	props<{ credentials: VerifyOTPDTO }>()
);
export const verifyOTPSuccess = createAction(
	'[Auth] Verify OTP Success',
	props<{
		serviceKey: string;
		userName: string;
	}>()
);
export const verifyOTPFailure = createAction(
	'[Auth] Verify OTP Failure',
	props<{ error: any }>()
);

export const termsAndConditions = createAction('[Auth] Terms And Conditions');
export const termsAndConditionsSuccess = createAction(
	'[Auth] Terms And Conditions Success',
	props<{
		versionId: string;
		termsAndConditionsContent: string;
		contentTypeId: string;
	}>()
);
export const termsAndConditionsFailure = createAction(
	'[Auth] Terms And Conditions Failure',
	props<{ error: any }>()
);

export const resendActivateCode = createAction(
	'[Auth] resend Activate Code',
	props<{ credentials: ResendActivateDTO }>()
);
export const resendActivateCodeSuccess = createAction(
	'[Auth] resend Activate Code Success',
	props<{ resendActivate: any }>()
);
export const resendActivateCodeFailure = createAction(
	'[Auth] resend Activate Code Failure',
	props<{ error: any }>()
);

interface UserAttributes {
	id: string;
	Status_id: string;
	UserName: string;
}
